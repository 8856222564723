nav{
    width: 100vw;
    height: 7rem;
    display: grid;
    place-items: center;
    background: var(--color-primary);
    position: fixed;
    top:0;
    left: 0;
    z-index: 9;
    transition: var(--transition);
    font-size: 1.1rem;
    font-weight: bold   ;
}

.nav__container{
    display: flex;
    justify-content: space-between;
    align-items: center; 
}

.nav__logo{
    width: 4rem;
    height: 4rem;
    border-radius: 20%;
    overflow: hidden;
}

.nav__menu{
    display: flex;
    gap: 3.5rem;
}

#theme__icon{
    background: transparent;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

/* HIDE NAV MENU ON TABLETS AND PHONES */
@media screen and (max-width: 1024px){
    .nav__menu{
        display: none;
    }
}

